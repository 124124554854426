<template>
	<edit-template ref="edit" @confirm="save('formData')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">

		<!-- 大表单 -->
		<div class="form-body">
			<el-form ref="formData" :model="form" label-width="100rem" style="width: 500rem" :rules="rules">
				<el-form-item label="手机号" prop="mobile">
					<el-input v-model.number="form.mobile" @change="changeInput" maxlength="11" placeholder="请输入您的手机号"></el-input>
				</el-form-item>
				<el-form-item label="登录密码" prop="password">
					<el-input v-model="form.password" placeholder="请输入您的密码" show-password=""></el-input>
				</el-form-item>
				<el-form-item label="姓名" prop="name">
					<el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
				</el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-select :popper-append-to-body="false" v-model="form.gender" placeholder="请选择您的性别">
						<el-option label="男" value="1"></el-option>
						<el-option label="女" value="0"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="角色" prop="roles">
					<el-select :popper-append-to-body="false" v-model="form.roles" placeholder="请选择您的角色">
						<el-option v-for="i in rolesArr" :label="i.name" :value="i.id" :key="i.id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<!-- 底部按钮 -->


</edit-template>
</template>

<script>
import { addUser, siteRole } from '@/api/account-management'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			checkData: [],
			rolesArr: [],
			input: '',
			form: {
				mobile: '', // 手机号
				password: '', // 密码
				name: '', // 姓名
				gender: '', // 性别
				roles: '', // 角色
        role_type: 3
				// role: '校区子管理员' // 角色
			},
			rules: {
				name: [
					{ required: true, message: '请输入您的姓名', trigger: 'blur' },
					{ min: 1, max: 10, message: '不超过十个字符', trigger: 'blur' }
				],
				mobile: [{ required: true, message: '请输入您的手机号', trigger: 'blur' }],
				password: [{ required: true, message: '请输入您的密码', trigger: 'blur' }],
				gender: [{ required: true, message: '请选择你的性别', trigger: 'change' }],
        roles: [{ required: true, message: '请选择你的角色', trigger: 'change' }],
			}
		}
	},

	methods: {
		save(formData) {
			this.$refs[formData].validate(valid => {
				if (valid) {
					addUser(this.form).then(res => {
						if (res.data.error.errorCode != 0) {
							this.$notify.error({
								title: '注册出错',
								message: res.data.error.errorUserMsg
							})
						} else {
							this.$notify({
								title: '注册成功',
								message: '注册成功',
								type: 'success'
							})
							setTimeout(() => {
                this.$store.commit("setPage",1);
								this.$router.back()
							}, 20)
						}
					})
				} else {
					return false
				}
			})
		},
		open() {
			this.$confirm('是否取消新增', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$router.back()
				})
				.catch(() => {})
		},
		changeInput(val) {
			var myreg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
			if (!myreg.test(val)) {
				this.$message.error('请输入正确手机号格式')
			}
		}
	},

	created() {
		siteRole({ type: 3 }).then(res => {
			this.rolesArr = res.data.data
		})
	}
}
</script>

<style lang="scss" scoped>
</style>
